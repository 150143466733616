import {
  isRouteErrorResponse,
  Link,
  useNavigate,
  useRouteError,
} from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { useCategories } from "~/old-app/api/category";
import { useAuthSlice } from "~/old-app/redux/auth-slice";
import { useConfigSlice } from "~/old-app/redux/config-slice";
import { cls } from "~/old-app/utils/helpers/cls";
// components
export default function NotFound() {
  const error = useRouteError();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { selectedService } = useConfigSlice();
  const { accessToken } = useAuthSlice();

  const { categories } = useCategories({
    service_id: selectedService,
    is_tree: true,
    featured: 1,
    enabled: !accessToken || !selectedService,
    limit: 1000,
  });

  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    navigate(`/${language}/categories/${category.slug || category.id}`);
  };

  return (
    <>
      <div className="mt-20 w-full flex flex-col justify-center items-center container">
        <img
          src="/defaults/404.png"
          loading="lazy"
          alt="404"
          className="w-96"
        />
        <button className="mt-5">
          <a className="relative inline-block text-sm font-medium text-white group active:text-primary-500 focus:outline-none focus:ring">
            <span className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-primary-600 group-hover:translate-y-0 group-hover:translate-x-0"></span>

            <span className="relative block px-8 py-3 bg-secondary-900 border border-current">
              <Link to="/">{t("global.goHome")}</Link>
            </span>
          </a>
        </button>

        <div className="grid grid-cols-4 md:grid-cols-7 gap-6 my-20">
          {categories?.map((category) => (
            <div
              key={`mobile-cat-${category.id}`}
              className="flex flex-col gap-1 cursor-pointer"
              onClick={() => handleCategoryClick(category)}
            >
              <img
                src={category.cover || DefaultImage}
                alt={category.name}
                loading="lazy"
                className="w-full h-auto  mx-auto mb-2 object-cover rounded-full"
                // className="w-32  h-32 md:h-40 md:w-40  mx-auto mb-2 object-cover rounded-full"
                onError={(e) => {
                  e.target.src = DefaultImage;
                  e.target.onerror = null;
                }}
              />

              <span className={cls("text-sm  line-clamp-2 text-center ")}>
                {category.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );

  // if (isRouteErrorResponse(error)) {
  //   if (error.status === 410) {
  //     return (
  //       <>
  //         <div className="mt-20 w-full flex flex-col justify-center items-center container">
  //           <img
  //             src="/defaults/404.png"
  //             loading="lazy"
  //             alt="404"
  //             className="w-96"
  //           />
  //           <button className="mt-5">
  //             <a className="relative inline-block text-sm font-medium text-white group active:text-primary-500 focus:outline-none focus:ring">
  //               <span className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-primary-600 group-hover:translate-y-0 group-hover:translate-x-0"></span>

  //               <span className="relative block px-8 py-3 bg-secondary-900 border border-current">
  //                 <Link to="/">{t("global.goHome")}</Link>
  //               </span>
  //             </a>
  //           </button>

  //           <div className="grid grid-cols-4 md:grid-cols-7 gap-6 my-20">
  //             {categories?.map((category) => (
  //               <div
  //                 key={`mobile-cat-${category.id}`}
  //                 className="flex flex-col gap-1"
  //                 onClick={() => handleCategoryClick(category)}
  //               >
  //                 <img
  //                   src={category.cover || DefaultImage}
  //                   alt={category.name}
  //                   loading="lazy"
  //                   className="w-full h-auto  mx-auto mb-2 object-cover rounded-full"
  //                   // className="w-32  h-32 md:h-40 md:w-40  mx-auto mb-2 object-cover rounded-full"
  //                   onError={(e) => {
  //                     e.target.src = DefaultImage;
  //                     e.target.onerror = null;
  //                   }}
  //                 />

  //                 <span className={cls("text-sm  line-clamp-2 text-center ")}>
  //                   {category.name}
  //                 </span>
  //               </div>
  //             ))}
  //           </div>
  //         </div>
  //       </>
  //     );
  //   }

  //   return (
  //     <div>
  //       <h1>
  //         {error.status} {error.statusText}
  //       </h1>
  //       <p>{error.data}</p>
  //     </div>
  //   );
  //   // throw new Error(`${error.status} ${error.statusText}`);
  // }

  // return (
  //   <div>
  //     <h1>404 Not Found</h1>
  //     <p>Sorry, the page you are looking for does not exist.</p>
  //   </div>
  // );
}
